// Colors
$black: #555;
$white: #fff;
$grey: #bbb;
$light-grey: #f2f2f2;
$light-blue: #3facff;
$pink: #f17878;
$dark-green: #0c3c3a;
$orange: #ffba49;
$dark-orange: #ffa10a;
$blue: #0000ff;

$color-primary: $light-grey;
$color-secondary: $orange;
$color-tercery: $dark-green;

// Typography
$family-sans-serif: 'Poppins', 'Arial', sans-serif !default;

$size-p: 16px !default;
$size-h1: 30px !default;
$size-h2: 25px !default;
$size-h3: 22px !default;
$size-h4: 20px !default;
$line: 1.5 !default;

$size-p-tablet: 18px;
$size-h1-tablet: 49px;
$size-h2-tablet: 36px;
$size-h3-tablet: 25px;

$weight-light: 300 !default;
$weight-normal: 400 !default;
$weight-medium: 500 !default;
$weight-semibold: 700 !default;
$weight-bold: 900 !default;

// Responsiveness
$title-bottom-padding: 20px;
$title-bottom-padding-tablet: 40px;

$wave-height-mobile: 50px;
$wave-height-tablet: 90px;
$wave-height-desktop: 130px;

$column-height: 250px;
$column16by9width: $column-height * 1.778;

// Shadow
$shadow-light: rgba(0, 0, 0, 0.1) 0px 5px 20px;
$shadow-hovered: rgba(0, 0, 0, 0.26) 0px 5px 20px;

// The container horizontal gap, which acts as the offset for breakpoints
$tablet: 769px !default;
$desktop: 1024px !default;
$widescreen: 1940px !default;

// Miscellaneous
$easing: ease-out !default;
$radius-small: 2px !default;
$radius: 4px !default;
$radius-large: 6px !default;
$radius-rounded: 290486px !default;
$speed: 86ms !default;

// Flags
$variable-columns: true !default;
