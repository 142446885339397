.work-tabs {
  button {
    background-color: rgba(0, 0, 0, 0);
    border: 0px;
    cursor: pointer;
    font-family: $family-sans-serif;
    padding: 0.5em 1em;
    margin-bottom: -1px;
    background-color: $color-secondary;
  }

  button:focus {
    border: 0.5px solid white;
    border-bottom-color: transparent !important;
    outline: 0px;
  }

  button:hover {
    background-color: $dark-orange;
  }

  ul {
    border-bottom-color: white;
  }

  .button-text {
    font-family: $family-sans-serif;
    font-size: 25px;
    color: white;
    font-weight: 400;
  }

  .icon {
    font-size: 25px;
    color: white;
  }

  .is-active {
    border: 0.5px solid white;
    border-bottom-color: transparent !important;
  }
}
