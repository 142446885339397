.menu-item {
	font-family: 'Poppins', sans-serif;
	width: 50%;
	font-size: 100px;
	display: inline-block;
	font-weight: 900;
			z-index: 2;
		
	:hover {
		color: $blue;
		-webkit-text-stroke-width: 0px;
	}

	.hover-item .hover {
		display: none;
	}

	.hover-item:hover .standard {
		display: none;
	}

	.hover-item:hover .hover {
		display: inline;
		font-size: 35px;
	}
}