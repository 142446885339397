.contact {
  background-color: $color-tercery;
  min-height: 102vh;
  display: block;

  .text {
    // margin-top: 50%;
  }
  h1 {
    font-family: $family-sans-serif, sans-serif;
    color: white;
    font-size: 40px;
    font-weight: 800;
  }
  h2 {
    font-family: $family-sans-serif, sans-serif;
    color: white;
    font-size: 30px;
    font-weight: 200;
  }
}
