.cards-component {
  width: 300px;
  height: 46rem;
  display: inline-block;
  margin: 10px;
  border-top: 10px solid $color-primary;
  border-left: 10px solid $color-primary;
  border-right: 5px solid $color-primary;
  border-bottom: 5px solid $color-primary;

  .card {
    width: 285px;
    height: 45rem;
  }

  img {
    height: 100%;
    width: auto !important;
    margin: auto;
  }

  .title {
    font-family: $family-sans-serif;
    font-weight: 900;
    font-size: 30px;
  }

  .subtitle {
    font-family: $family-sans-serif;
    font-weight: 700;
    font-size: 20;
  }

  .date {
    font-size: 14px;
    font-family: $family-sans-serif;
    margin-top: -20px;
  }

  .card {
    max-width: 300px;
    display: inline-block;
  }

  .skills {
    min-height: 300px;
  }

  .skills-tags {
    margin-right: 3px;
    background-color: $color-tercery;
    color: white;
  }

  .date-tag {
    background-color: $color-primary;
    color: black;
  }

  .description {
    margin-bottom: 20px;
  }
}
