@keyframes glitch {
  0% {
    background-color: $color-primary;
  }
  1% {
    background-color: #00ff00;
  }
  2% {
    background-color: black;
  }
  3% {
    background-color: $color-primary;
  }
  100% {
    background-color: $color-primary;
  }
}

.homepage {
  background-color: $color-primary;
  min-height: 100vh;
  display: block;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: white;
  //   animation-name: glitch;
  //   animation-duration: 5s;
  //   animation-delay: 5s;
  //   animation-iteration-count: infinite;

  a {
    color: rgba(0, 0, 0, 0);
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: $blue;
  }

  .hover-item {
    color: rgba(0, 0, 0, 0);
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: $blue;
  }

  .menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100vh;
  }

  .menu>div {
    display: flex;
    flex-flow: wrap column;
    justify-content: space-evenly;
  }

  .btn-homepage {
    font-family: 'Poppins', sans-serif;
    width: 50%;
    font-size: 100px;
    display: inline-block;
    font-weight: 900;
    z-index: 2;

    :hover {
      color: $blue;
      -webkit-text-stroke-width: 0px;
    }
  }

  .contact-card {
    display: none;
  }

  .contact-button:hover + .contact-card {
    display: block;
    font-size: 20px;
    position: absolute;
    top: 150px;
    right: 15px;
    -webkit-text-stroke-width: 0px;
    color: white;
    text-align: right;
  }

  .bottom-right {
    right: 15px;
    bottom: 15px;
    position: absolute;
  }

  .image {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .image-phone {
    display: none;
  }
}

@media (max-width: $tablet) {
  .homepage {
    overflow-y: hidden;
    overflow: hidden;
    a {
      -webkit-text-stroke-width: 1px;
    }

    .btn-homepage {
      font-size: 35px;
    }

    .image {
      display: none;
    }

    .image-phone {
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      display: inline;
      overflow-y: hidden;
    }

    .comingsoon-tag {
      font-size: 15px;
      bottom: 15px;
    }

    .top-right {
      top: 5px;
    }

    .top-left {
      top: 5px;
    }
  }
}
